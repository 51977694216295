.container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 10px;
  text-align: center;
  background: #5E9EFF;
}

.logo {
  font-family: 'Rubik Puddles';
  font-size: 34px;
  color: yellow;
}

img {
  display: block;
  margin: auto;
  width: 50%;
}

img.frame-caution {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  border: 60px solid;
  padding: 2px;
  border-image: url(../../img/frame-caution-tape.webp) 30% round;
  -webkit-border-image: url(../../img/frame-caution-tape.webp) 30% round; /* Safari 3.1-5 */
  -o-border-image: url(../../img/frame-caution-tape.webp) 30% round; /* Opera 11-12.1 */
}